import "./../login/login.css";
import "./otp.css";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "react-bootstrap/";
import { TextField, Button } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import axios from "../../network/api";
import { baseUrl } from "../../network/constants/Constants";
import AppConstants from "../../network/AppConstants";
import Logo from "../../../assets/logo.svg";

function Otp() {
  const location = useLocation();
  const phone = location.state?.data.phone;
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
const [invalidOtp, setInvalidOtp] = useState("")
  const [otpError, setOtpError] = useState(false);
  const navigate = useNavigate();


  const handleOTP = (event) => {
    setOTP(event.target.value);
    setInvalidOtp("");
    setOtpError("");
  };
  const validateOTP = (otp) => {
    const regex = /^\d{6}$/;
    setOtpError(!regex.test(otp));
    return regex.test(otp);
  };
  const PostOTP = (props) => {
    setLoading(true);
    setInvalidOtp("")
    const payload = {
      phone: phone,
      otp: otp,
    };


   

    axios
      .post(baseUrl + "auth/auth/business-validate/", payload)
      .then((response) => {
        setLoading(false);
        localStorage.setItem(AppConstants.JWT,response.data["token"]);
        callDeleteAPI();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          setInvalidOtp("Invalid OTP");
        }
      });
  };


  const callDeleteAPI = (props) => {
    const authToken = localStorage.getItem(AppConstants.JWT);
    const config = {
      headers: { Authorization: `Token ${authToken}` },
    };
    setLoading(true);
    axios
      .delete(baseUrl + "auth/user-delete/",config)
      .then((response) => {
        setLoading(false);
        localStorage.setItem(AppConstants.JWT,response.data["token"]);
        navigate("../deleted", { replace: true });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <section id="login">
      <div className="content">
      <div className="brand-container">
        <img alt="logo" src={Logo} className="logo"/>
        <div className="business-name">Onescribe</div>
        </div>
        <Card className="login-card card mt-5 rounded-4">
          <div className="login-title">OTP Verification</div>

          <TextField
            id="otp"
            className="mt-4"
            label="Enter OTP"
            variant="outlined"
            size="small"
            type="number"
            value={otp}
            onChange={handleOTP}
            error={otpError}
            helperText={otpError ? "Invalid OTP Number" : ""}
          />

          <Button
            variant="contained"
            className="login-btn mt-5 mb-3"
            onClick={() => {
              if (validateOTP(otp)) {
                setLoading(true);
                PostOTP();
              }
            }}
          >
            Submit
          </Button>
          <div className="not-number">
            {phone}
            <div onClick={()=>navigate("../login", {replace: true})}>Not your Phone Number? Click to Go Back </div>
          </div>
          <div className="spiner-container">
          <TailSpin
            className="mt-5"
            height="30"
            width="30"
            radius="1"
            color="#f4b701"
            ariaLabel="loading"
            visible={loading}
          />
          </div>
        </Card>
      </div>

    
    </section>
  );
}

export default Otp;

