import React, { useState } from "react";
import "./contact.css";
import { Col, Container, Row } from "react-bootstrap";
import contact from "../../assets/contact/contact.png";
import { useForm } from "react-hook-form";
import "font-awesome/css/font-awesome.min.css";
import triangle from "../../assets/contact/triangle.svg";
import axios from "axios";
import { baseUrl, config } from "../../components/network/constants/Constants";
import { CiUser } from "react-icons/ci";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

function Contact() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    trigger,
  } = useForm();

  function handleSubmitMessage(e) {
    submitMail();
    setName("");
    setPhone("");
    setMail("");
    setMessage("");
    reset();
  }

  const submitMail = () => {
    setLoading(true);
    const payload = {
      name: name,
      email: mail,
      phone: phone,
      message: message,
    };

   
    axios
      .post(baseUrl + "web/contact-us/", payload)
      .then((response) => {})
      .catch((error) => {
        setLoading(false);
      });
  };

  const clearInputError = (fieldName) => {
    setError(fieldName, {
      type: "manual",
      message: "",
    });
  };

  return (
    <section id="contact">
      <div className="main-heading contact-head">Contact Us</div>
      <Container>
        <div className="contact-section">
          <Row>
            <Col lg={6}>
              <div className="contact-image">
                <img src={contact} alt="contact" className="contact-cartoon" />
              </div>
              <div className="triangle-image">
                <img src={triangle} alt="contact" className="triangle-art" />
              </div>
            </Col>
            <Col lg={6} className="form-column">
              <div className="contact-form">
                <div className="form-head">Connect with us</div>
                <div className="test">
                  <form onSubmit={handleSubmit(handleSubmitMessage)} >
                    <div className="form-container">
                    <div className="contact-textfield-div">
                      <TextField
                        id="name"
                        type="text"
                        required=""
                        placeholder="Name"
                        autoComplete="off"
                        className={`contact-input-field name-field ${
                          errors.name && "invalid"
                        }`}
                        {...register("name", {
                          required: "Name is Required !",
                        })}
                        onChange={(e) => {
                          clearInputError("name");
                          setName(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {errors.name && (
                        <small className="error-text">
                          {errors.name.message}
                        </small>
                      )}
                    </div>

                    <div className="contact-textfield-div">
                      <TextField
                        id="email"
                        type="email"
                        required=""
                        placeholder="Email"
                        className={`contact-input-field email-field ${
                          errors.email && "invalid"
                        }`}
                        {...register("email", {
                          required: "Email is Required !",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address !",
                          },
                        })}
                        onChange={(e) => {
                          clearInputError("email");
                          setMail(e.target.value);
                        }}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {errors.email && (
                        <small className="error-text">
                          {errors.email.message}
                        </small>
                      )}
                    </div>
                  
                    <div className="contact-textfield-div">
                      <TextField
                        id="number"
                        type="tel"
                        required=""
                        placeholder="Phone Number"
                        className={`contact-input-field number-field ${
                          errors.phone && "invalid"
                        }`}
                        {...register("phone", {
                          required: "Phone is Required !",
                          pattern: {
                            value:
                              /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                            message: "Invalid phone no !",
                          },
                        })}
                        onChange={(e) => {
                          clearInputError("phone");
                          setPhone(e.target.value);
                        }}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneAndroidIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {errors.phone && (
                        <small className="error-text">
                          {errors.phone.message}
                        </small>
                      )}
                    </div>
                   
                    <div className="contact-textfield-div">
                      <TextField
                          id="message"
                          type="text"
                          name="subject"
                          multiline
                          rows={4}
                          placeholder="Write a message"
                          className={`contact-input-msg msg-field ${
                            errors.message && "invalid"
                          }`}
                          {...register("message", {
                            required: "Message is Required !",
                          })}
                          onChange={(e) => {
                            clearInputError("message");
                            setMessage(e.target.value);
                          }}
                        variant="outlined"
                        Rows={6}
                      
                      />
                         {errors.message && (
                        <small className="error-text">
                          {errors.message.message}
                        </small>
                      )}
                    </div>
                    </div>
                    
                    <div className="contact-btn">
                      <input
                        type="submit"
                        className="collabe-button"
                        value="Submit"
                        onClick={handleSubmit(handleSubmitMessage)}
                      />
                      <div className="triangle-image">
                        <img
                          src={triangle}
                          alt="contact"
                          className="triangle-art2"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default Contact;
