import "./login.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap/";
import { TextField, Button } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import axios from "../../network/api";
import { baseUrl } from "../../network/constants/Constants";
import Logo from "../../../assets/logo.svg";

function Login() {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [unauthorizedError, setUnauthorizedError] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const navigate = useNavigate();

  const handlePhone = (event) => {
    setPhone(event.target.value);
    setUnauthorizedError(""); 
    setPhoneError("")
  };

  const validatePhone = (phone) => {
    const regex = /^(?:\+91\s?)?[6-9]\d{9}$/;
    setPhoneError(!regex.test(phone));
    return regex.test(phone);
  };

  const PostLogin = (props) => {
    setLoading(true);
    setUnauthorizedError("");
    const payload = {
      phone: phone,
    };

    axios
      .post(baseUrl + "auth/auth/login/", payload)
      .then((response) => {
        setLoading(false);
        const dataToSend = { phone: phone };
        navigate("../otp", { state: { data: dataToSend }, replace: true });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          setUnauthorizedError("No account associate with this phone number.");
        }
      });
  };

  return (
    <section id="login">
      <div className="content">
        <div className="brand-container">
          <img alt="logo" src={Logo} className="logo" />
          <div className="business-name">Onescribe</div>
        </div>
        <Card className="login-card card mt-5 rounded-4">
          <div className="login-title">Login</div>

          <TextField
            id="email"
            className="mt-4"
            label="Phone"
            variant="outlined"
            size="small"
            type="email"
            value={phone}
            onChange={handlePhone}
            error={phoneError || !!unauthorizedError}
            helperText={phoneError || unauthorizedError || ""}
          />

          <Button
            variant="contained"
            className="login-btn mt-5 mb-3"
            onClick={() => {
              if (validatePhone(phone)) {
                setLoading(true);
                PostLogin();
              }
            }}
          >
            Submit
          </Button>
          <div className="spiner-container">
            <TailSpin
              className="mt-5"
              height="30"
              width="30"
              radius="1"
              color="#f4b701"
              ariaLabel="loading"
              visible={loading}
            />
          </div>
        </Card>
      </div>

      <a href="tel:" className="contact">
        ⓘ Contact Us
      </a>
    </section>
  );
}

export default Login;
