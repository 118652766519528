

export const baseUrl = "https://admin.onescribe.app/";
export const baseMediaUrl = "http://127.0.0.1:8000/";

// export const config = {
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//   },
// };
